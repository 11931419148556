import React, { useEffect } from 'react';
import api from '../../services/api';
import { useParams } from "react-router-dom";

const Swal = require('sweetalert2');

export default function Status_account({ history }) {

    //Pegando da URL PARAMS o valor do token para alterar senha
    const { token } = useParams();

    useEffect(() => {
        async function updateStatus() {
            await api.put('/update/account/status/:token', {
                token: token
            }).then(response =>{
                Swal.fire({
                    icon: 'success',
                    title: 'Sucesso!',
                    text: 'Conta ativada com sucesso!',
                });

                localStorage.clear();
                sessionStorage.clear();

                history.push('/');
            }).catch(error =>{
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao ativar conta!',
                    text: error.response.data,
                  });
                  history.push('/');
            });
        };

        updateStatus();
    }, [history, token]);

    return (
        <>
        <p><strong>Tudo certo! Você ativou a sua conta com sucesso!</strong></p>
        </>
    )
}
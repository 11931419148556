import React, { useEffect, useState } from "react";
import { fetchCitiesForState, parseCities } from "../../../helpers/ibge";

const DropdownBrazilianCities = ({id, name, state, onChange = () => {} }) => {

    const [cities, setCities] = useState([]);

    useEffect(()=>{
        fetchCitiesForState(state).then(parseCities).then((cities) => {
            setCities(cities);
        })
    }, [state]);

    return(
        <select id={id || name} name={name || id} onChange={onChange}>
            <option value="">Selecione uma Cidade...</option>
            {cities.map((city)=>{
                const {label, value} = city;
                return (<option value={label} key={value}>{label}</option>);
            })}
        </select>
    );
};

export default DropdownBrazilianCities;
import React from 'react';

export default function TipoGarantia({ history }) {

    async function logout() {
        localStorage.clear();
        sessionStorage.clear();
        
        history.push('/');
    }

    async function handleSubmit(tipoGarantia_id){
        //Pegando somente o ID do tipo de garantia
        localStorage.setItem('tipoGarantia', tipoGarantia_id);

        history.push('/consultaNfe');
      }

    return (
        <>
        <p><strong>Selecione o Tipo de Garantia que você irá emitir: </strong></p>
        <button className="btn" id="btnAutomotivo" type="submit" onClick={event => handleSubmit(event.target.value)} value="1" >Garantia Automotiva</button>
        <button className="btn" id="btnArquitetura" type="submit" onClick={event => handleSubmit(event.target.value)} value="2">Garantia Arquitetônica</button>
        <button className="btn" id="btnDecorativa" type="submit" onClick={event => handleSubmit(event.target.value)} value="3">Garantia Decorativa</button>
        <button className="btn" id="btnCobertura" type="submit" onClick={event => handleSubmit(event.target.value)} value="4">Garantia Cobertura</button>
        <div>
            <button className='btnLogout' onClick={logout}>Sair da conta</button>
        </div>
        </>
    )
}
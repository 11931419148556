import React from 'react';
import { useState, useEffect, useRef } from 'react';
import {mask, unMask} from 'remask';
import api from '../../services/api'
import { cpf, cnpj } from 'cpf-cnpj-validator'; 
import spinnerImg from '../../assets/spin.svg';
import Swal from 'sweetalert2';

import './styles.css'

import DropdownBrazilianCities from '../../components/Forms/DropdownBrazilianCities';
import DropdownBrazilianStates from '../../components/Forms/DropdownBrazilianStates';

import { useHistory } from 'react-router';

export default function Garantia(){

    const [formValues, setFormValues] = useState({});
    const [cpfCnpjValues, setcpfCnpjValues] = useState("");
    const [telefoneValues, setTelefoneValues] = useState("");
    const [destNameValues] = useState("Nome");
    const [destCNPJCPFValues] = useState("CNPJ/CPF");
    const [prods, setProds] = useState([]);
    const [prodsLojaSite, setProdsLojaSite] = useState([]);
    const [prodsByCprod, setProdsByCprod] = useState([]);
    const [nomeCliente, setNomeCliente] = useState("");
    const [emailCliente, setEmailCliente] = useState("");
    const [modeloCarro, setModeloCarro] = useState("");
    const [placaCarro, setPlacaCarro] = useState("");
    const [observacao, setObservacao] = useState("");
    const [data, setData] = useState("");
    const [quantidadeUsada, SetQuantidadeUsada] = useState([]);
    const [numeroLote, setNumeroLote] = useState([]);
    const [loading, setLoading] = useState(false);

    const siglaEstado = formValues.state;
    const nomeCidade = formValues.cidade;

    const firstRenderRef = useRef(true);
    
    const history = useHistory();

    useEffect(() => {
        async function loadProds() {
            //Pegando todas as películas que o cliente comprou
            const chaveAcessoUsuario = localStorage.getItem('chaveAcesso');
            const response = await api.get('/produtoByFk_Nfe/' + chaveAcessoUsuario );

            setProds(response.data);
        };

        async function loadProdsByTipoGarantia() {
            //Trazando todas as Películas da Loja cujo tipogarantia_id = tipoGarantia localStorage;
            const tipoGarantiaLS = localStorage.getItem('tipoGarantia');

            const prodsLS = await api.post('/cprod/tipoGarantia', {
                tipoGarantia_id: tipoGarantiaLS
            });

            setProdsLojaSite(prodsLS.data);
        };      
        

        loadProds();
        loadProdsByTipoGarantia();
    }, []);

    useEffect(() => {
        async function loadProdsByCprod(){
            //Pegando cProd dos produtos que o cliente comprou
            const cProdComprada = prods.map(prod =>{
                return prod.cProd;
            });

            //Pegando todos os códigos do Produto da Loja e Site(Já vem concatenado do BD)
            const ProdsElements = prodsLojaSite.map(el =>{
                return el.cProd;
            });
        
            if(ProdsElements.length > 0 && cProdComprada.length > 0){
                //Comparando os códigos do ProdsElements com o qual o cliente comprou.
                const novaArrayLoja = Object.values(ProdsElements).filter(elements => cProdComprada.includes(elements)); 

                //Comparando os códigos que retornaram do filtro acima com os Produtos que o cliente comprou.
                const infosItemComprado = Object.values(prods).filter(elements => novaArrayLoja.includes(elements.cProd)); 
                setProdsByCprod(infosItemComprado);
            }            
        }

        loadProdsByCprod();
    }, [prods, prodsLojaSite]);

    //Verificação para ver se usuário tem película no Tipo de Garantia escolhido 
    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return;
        }

        if (prodsByCprod.length < 1) {
            Swal.fire({
                icon: 'error',
                title: 'Erro:',
                text: 'Você não tem produtos para este tipo de Garantia.'
              });
            history.push('/');
        }
        
    }, [prodsByCprod, history]);

    //Verificação para esconder campos "modeloCarro" e "placaCarro" caso o tipo de garantia seja igual a 2,3 ou 4.
    useEffect(() => {
        const tipoGarantia = localStorage.getItem('tipoGarantia');

        async function hideFields() {
            if (tipoGarantia === "2" || tipoGarantia === "3" || tipoGarantia === "4"){
                //Retirando do display o campo Modelo Carro e tirando o required 
                document.getElementById("modeloCarro").style.display = 'none';
                document.getElementById("modeloCarro").removeAttribute("required");

                //Retirando do display o campo Placa Carro e tirando o required 
                document.getElementById("placaCarro").style.display = 'none';
                document.getElementById("placaCarro").removeAttribute("required");

                //Retirando o Label dos campos "Placa Carro" e "Modelo Carro"
                document.getElementById("modeloCarroLabel").style.display = 'none';
                document.getElementById("placaCarroLabel").style.display = 'none';
            }
        }

        hideFields();
    }, []);

    //Deixando Inputs desabilitados caso a Quantidade Disponível seja = 0;
    useEffect(() => {

        async function hideFieldsPel() {
            for (var i = 0; i < prodsByCprod.length; i++){
                const qtdDisponivel = document.getElementById(`quantidadeDisponível-${i + 1}`);

                if(qtdDisponivel.value <= 0){
                    //Desabilitando o input da Quantidade Usada
                    document.getElementById(`quantidadeUsada-${i + 1}`).disabled = true;
                    document.getElementById(`quantidadeUsada-${i + 1}`).placeholder = "";

                    //Desabilitando o input do Número de Lote
                    document.getElementById(`numeroLote-${i + 1}`).disabled = true;
                    document.getElementById(`numeroLote-${i + 1}`).placeholder = "";
                }
            }
        }

        hideFieldsPel();
    }, [prodsByCprod]);

    //Pegando a Metragem comprada de cada produto
    const qtdByCprod = prodsByCprod.map(prod => {
        return prod.quantidadeComprada;
    });

    //Pegando o ID do produto
    const id_ProdNfe = prodsByCprod.map(prod =>{
        return prod.produtoNfe_id;
    });

    //Função para definir a data máxima de escolha para a atual
    function pegarDataAtual(){
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth()+1; //January is 0!
        var yyyy = today.getFullYear();
         if(dd<10){
                dd='0'+dd
            } 
            if(mm<10){
                mm='0'+mm
            } 
        
        today = yyyy+'-'+mm+'-'+dd;
        return today;
    };

    const handleInputChange = (e) => {
        e.preventDefault();
        const {value, name} = e.target;
        setFormValues({...formValues, [name]:value })
    };

    const onChangeQtdUsada = (e, index) => {
        quantidadeUsada[index] = e.target.value;
        SetQuantidadeUsada([...quantidadeUsada]);
        //console.log(quantidadeUsada);
    };

    const onChangeNrmLote = (e, index) => {
        numeroLote[index] = e.target.value;
        setNumeroLote([...numeroLote]);
        //console.log(numeroLote);
    };

    const handleMaskCPFxCNPJ = (e) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, ['999.999.999-99', '99.999.999/9999-99']);
        setcpfCnpjValues(maskedValue);
    };

    const handleMaskTelefone = (e) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, ['(99) 9999-9999', '(99) 9 9999-9999']);
        setTelefoneValues(maskedValue);
    };

    function validacaoQtdUsada(){
         //Vai pegar todos os valores do array por posição e ver se o qual ele digitou é maior
         for(var i = 0; i < qtdByCprod.length; i++){
             if (quantidadeUsada[i] > qtdByCprod[i]){
                 return false;
             }
         }
    };

    function validacaoQtdUsadaxNmrLote(){    
        //Validação para caso o usuário digite dê submit no formulário com um campo vazio e outro undefined ou vazio. 
         for(var i = 0; i < qtdByCprod.length; i++){
             /*valores false, undefined, null, NaN , 0 , "", '' 
             são valores falsy em javascript, todos eles são a mesma coisa que false*/
             if( (!numeroLote[i] && quantidadeUsada[i]) || (numeroLote[i] && !quantidadeUsada[i]) ){
                return false;
             }
         }
    };

    function tirarVazioArray(){    
        //Tirando do Array caso quantidade Usada estiver vazia ""
         for(var i = 0; i < quantidadeUsada.length; i++){
            if (!quantidadeUsada[i]){
                quantidadeUsada.splice(i, 1);
                numeroLote.splice(i, 1);
                id_ProdNfe.splice(i, 1);
                qtdByCprod.splice(i, 1);
                i--;
            }
         }
    };

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    async function handleSubmit(event){
        event.preventDefault();

        var btn = document.getElementById("btnSubmit");
        btn.disabled = true; 
        btn.style.backgroundColor = '#FFEBCD';
        btn.innerText ='Gerando, aguarde...';

        if(unMask(telefoneValues).length < 10){
            Swal.fire({
                icon: 'error',
                title: 'Erro:',
                text: 'Número de telefone inválido, é necessário conter 10 ou mais digitos.',
              });
            btn.disabled = false;
            btn.style.backgroundColor = '#ff9600';
            btn.innerText ='Emitir Certificado';
        }
        else if(cnpj.isValid(unMask(cpfCnpjValues)) === false && cpf.isValid(unMask(cpfCnpjValues)) === false){
            Swal.fire({
                icon: 'error',
                title: 'Erro:',
                text: 'Por Favor, digite um CPF/CNPJ válido!',
              });
            btn.disabled = false;
            btn.style.backgroundColor = '#ff9600';
            btn.innerText ='Emitir Certificado';
        }
        else if(validacaoQtdUsada() === false){
            Swal.fire({
                icon: 'error',
                title: 'Erro:',
                text: 'Quantidade Usada é maior que Quantidade Disponível, verifique e tente novamente!',
              });
            btn.disabled = false;
            btn.style.backgroundColor = '#ff9600';
            btn.innerText ='Emitir Certificado';
        }
        else if(validateEmail(emailCliente) === false){
            Swal.fire({
                icon: 'error',
                title: 'Erro:',
                text: 'Por favor verifique se o Email contém @ e . e tente novamente!',
              });
            btn.disabled = false;
            btn.style.backgroundColor = '#ff9600';
            btn.innerText ='Emitir Certificado';
        }
        else if(validacaoQtdUsadaxNmrLote() === false){
            Swal.fire({
                icon: 'error',
                title: 'Erro:',
                text: 'Verifique se digitou os dois campos da Película escolhida! (Quantidade Usada e Número de Lote).',
              });
            btn.disabled = false;
            btn.style.backgroundColor = '#ff9600';
            btn.innerText ='Emitir Certificado';
        }
        else {
            try{

                setLoading(true);

                tirarVazioArray();
                
                await api.post('/consumidor/create/V2',
                    { 
                    nome: nomeCliente, 
                    cpfCnpj: unMask(cpfCnpjValues), 
                    telefone: unMask(telefoneValues), 
                    estado: siglaEstado, 
                    cidade: nomeCidade, 
                    email: emailCliente, 
                    modeloCarro: modeloCarro, 
                    placaCarro: placaCarro,
                    quantidadeUsada: quantidadeUsada,
                    numeroLote: numeroLote,
                    fk_produtoNfe_id: id_ProdNfe,
                    dataInstalacao: data, 
                    observacoes: observacao,
                    fk_garantia_id: localStorage.getItem("tipoGarantia")
                    }, {
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem('token')}`
                        }
                    }).then(response =>{
                        if (response.status === 200){

                            async function AlterarQtd(){
                                for (var i = 0; i < quantidadeUsada.length; i++){

                                    var saldo = qtdByCprod[i] - quantidadeUsada[i];
    
                                    await api.put('/qtdComprada', {
                                        quantidadeComprada: saldo,
                                        produtoNfe_id: id_ProdNfe[i]
                                    }).catch(error =>{
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Erro:',
                                            text: 'Não foi possível atualizar a quantidade Usada.',
                                          });
                                    });
                                }
                            }

                            async function criarPDF(){

                                const resp = await api.post('/consumidor/id/prods', {
                                    consumidor_id: response.data,
                                    tipoGarantia_id: localStorage.getItem('tipoGarantia')
                                });

                                var newPeliculas = resp.data;

                                await api.post('/create/pdf', {
                                    path: "/automotivav2.ejs",
                                    nomeInstalador: localStorage.getItem('destNome'),
                                    nomeCliente: nomeCliente,
                                    cpfCnpjInstalador: localStorage.getItem('destCNPJCPF'),
                                    cpfCnpjCliente: cpfCnpjValues,
                                    telefoneInstalador: localStorage.getItem('destTelefone'),
                                    telefoneCliente: telefoneValues,
                                    emailInstalador: localStorage.getItem('destEmail'),
                                    emailCliente: emailCliente,
                                    cidadeInstaldor: localStorage.getItem('destCidade'),
                                    estadoInstalador: localStorage.getItem('destEstado'),
                                    cidadeCliente: nomeCidade,
                                    estadoCliente: siglaEstado,
                                    peliculas: newPeliculas,
                                    modeloCarro: modeloCarro,
                                    placaCarro: placaCarro,
                                    dataInstalacao: data,
                                    Observacoes: observacao
                                }, { responseType: 'blob' }).then(response => {
                                    //baixarPDF();
                                    if (response.data.error) {
                                        console.error(response.data.error)
                                        }
                
                                        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
                                        const fileLink = document.createElement('a');
                                        fileLink.href = fileURL;
                                        const fileName = response.headers['content-disposition'].substring(22, 52);
                                        fileLink.setAttribute('download', fileName);
                                        document.body.appendChild(fileLink);
                                        fileLink.click();
                                        fileLink.remove();

                                        //LIMPANDO OS DADOS DO LOCALSTORAGE E ENCAMINHANDO PARA A PÁGINA PRINCIPAL
                                        setLoading(false);
                                        Swal.fire({
                                            icon: 'success',
                                            title: 'Sucesso!',
                                            text: 'A garantia foi emitida e encaminhada por e-mail!',
                                        });
                                        localStorage.clear();
                                        sessionStorage.clear();
                                        history.push('/');
                                }).catch(error =>{
                                    const reader = new FileReader();
                                    reader.onload = function(e) {
                                        // ...
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Erro:',
                                            text: 'Não foi possível emitir o Certificado. '+e.target.result,
                                          });
                                    };
                                    reader.readAsText(error.response.data);
                                });
                                
                            }
                            AlterarQtd();
                            criarPDF();;
                        };
                    }).catch(err =>{
                        Swal.fire({
                            icon: 'error',
                            title: 'Erro:',
                            text: 'Não foi possível inserir as informações da Garantia, tente novamente mais tarde.',
                          });
                    });
                }catch(error) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Erro:',
                        text: 'Erro ao emitir a Garantia, favor tentar novamente mais tarde',
                      });
                }
        }
    }

    return (
        <>
        <form onSubmit={handleSubmit}>
            <p><strong>Informações do Instalador:</strong> </p>
            <label htmlFor="xNomeInstalador">Nome</label>
            <input id="xNome" type="text" onChange={event => destNameValues(event.target.value)} value={localStorage.getItem('destNome')} disabled/>
            
            <label htmlFor="xCPFxCNPJInstalador">CPF/CNPJ</label>
            <input id="xCPFxCNPJ" type="text" onChange={event => destCNPJCPFValues(event.target.value)} defaultValue={localStorage.getItem('destCNPJCPF')} disabled/>

            <p><strong>Informações do Cliente:</strong></p>
            <label className="required" htmlFor="xNomeCliente">Nome</label>
            <input id="xNomeCliente" type="text" value={nomeCliente} onChange={event => setNomeCliente(event.target.value)} required/>

            <label className="required" htmlFor="xCPFxCNPJCliente">CPF/CNPJ</label>
            <input id="xCPFxCNPJCliente" type="text" placeholder="Digite o CPF ou CNPJ" onChange={handleMaskCPFxCNPJ} value={cpfCnpjValues} required/>

            <label className="required" htmlFor="telefone">Telefone</label>
            <input id="telefone" type="text" placeholder="Ex: (99) 9 9999-9999" onChange={handleMaskTelefone} value={telefoneValues} required/>

            <label className="required" htmlFor="state">Estado</label>
            <DropdownBrazilianStates id="state" name="state" onChange={handleInputChange}/>

            <label className="required" htmlFor="cidade" >Cidade</label>
            <DropdownBrazilianCities id="cidade" name="cidade" state={formValues.state} onChange={handleInputChange}/>

            <label className="required" htmlFor="email">E-mail</label>
            <input id="email" type="email" value={emailCliente} onChange={event => setEmailCliente(event.target.value)} required/>  
            
            <label id="modeloCarroLabel" className="required" htmlFor="modeloCarro">Modelo do Carro</label>
            <input id="modeloCarro" type="text" value={modeloCarro} onChange={event => setModeloCarro(event.target.value)} maxLength="60" required/>       

            <label id="placaCarroLabel" className="required" htmlFor="placaCarro">Placa do Carro</label>
            <input id="placaCarro" type="text" value={placaCarro} onChange={event => setPlacaCarro(event.target.value)} maxLength="8" required/>     

            {loading ? <img id="imgSpinner" name="imgSpinner" src={spinnerImg} alt="Loading"></img> : false}

            <p><strong>Informações da Película:</strong></p>   

            <p id="pInformacoes" >Deixe em branco as películas que não deseja fazer a garantia.</p>

            <ul className="prods-list">
                {prodsByCprod.map((prod, index) => (
                    <li key={prod.modeloPelicula}>
                        <label htmlFor="peliculas">Modelo Pelicula</label>
                        <input name="peliculas" type="text" value={prod.modeloPelicula} disabled/>
                        <label htmlFor="quantidadeDisponível">Quantidade Disponível</label>
                        <input id={`quantidadeDisponível-${index+1}`} name="quantidadeDisponível" type="text" value={prod.quantidadeComprada} disabled/>

                        <div key={index}>
                            <label htmlFor={`quantidadeUsada-${index+1}`}>Quantidade Aplicada (Em Metros)</label>
                            <input name="quantidadeUsada" type="number" id={`quantidadeUsada-${index+1}`} onChange={(e) => onChangeQtdUsada(e, index)} step="any" min="0.1" placeholder="Ex: 1.2"/>

                            <label htmlFor={`numeroLote-${index+1}`}>Número do Lote</label>
                            <input name="numeroLote" type="text" id={`numeroLote-${index+1}`} maxLength="20" onChange={(e) => onChangeNrmLote(e, index)} placeholder="OU Número NF-e"/> 
                        </div>
                    </li>
                ))}
            </ul>     

            <p><strong>Informações Complementares:</strong></p>

            <label className="required" htmlFor="dataInstalacao">Data Instalação</label>
            <input id="dataInstalacao" type="date" value={data} onChange={event => setData(event.target.value)} min="2010-01-01" max={pegarDataAtual()} required/>

            <label htmlFor="observacoes">Observações da Aplicação</label>
            <textarea id="observacoes" type="text" value={observacao} maxLength="80" onChange={event => setObservacao(event.target.value)}/>

        <button id="btnSubmit" className="btn" type="submit">Emitir Certificado</button>
        </form>
        </>
    )
}
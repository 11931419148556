import './App.css';

import React from 'react';

import logo from './assets/logopreta.png';

import Routes from './routes';

function App() {
  return (
    <div className="container">
      <img src={logo} className="logo" alt="Sun Protect" />
      <div className="content">
        <Routes/>
      </div>
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import api from '../../services/api';
import spinnerImg from '../../assets/spin.svg';

const Swal = require('sweetalert2');

export default function Forgot_password({ history }){

    const [emailLogin, setEmailLogin] = useState("");
    const [loading, setLoading] = useState(false);

    //Função para validação de E-mail
    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    //Para desabilitar o botão assim que for feito a primeira requisição
    function disabledBtn() {
        var btn = document.getElementById("btnSubmit");
        btn.disabled = true; 
        btn.style.backgroundColor = '#F0E68C'
    };

    //Para habiltar o botão novamente caso caia no erro
    function abledBtn() {
        var btn = document.getElementById("btnSubmit");
        btn.disabled = false;
        btn.style.backgroundColor = '#ff9600';
    };
    
    async function handleSubmit(event){
        event.preventDefault();

        disabledBtn();

        if(validateEmail(emailLogin) === false){
            Swal.fire({
                icon: 'error',
                title: 'Erro:',
                text: 'Por favor verifique se o Email contém @ e . e tente novamente!',
              });
              abledBtn();
        } else{
            setLoading(true);

            await api.put('/forgot/password', {
                email: emailLogin
            }).then(response =>{
                Swal.fire({
                    icon: 'success',
                    title: 'Sucesso!',
                    text: 'Link para redefinição de senha enviado por e-mail!',
                });

                setLoading(false);
                history.push('/');
            }).catch(error =>{
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Erro na redefinição de senha',
                    text: error.response.data,
                    //text: error.response.data
                  });
                  abledBtn();
            });
        }
    }

    return (
        <>
        <form onSubmit={handleSubmit}>
            <label><strong>Email </strong></label>
            <input
                id="emailLogin"
                required
                maxLength="100"
                onChange={event => setEmailLogin(event.target.value)}
            />

            {loading ? <img id="imgSpinner" name="imgSpinner" src={spinnerImg} alt="Loading"></img> : false}

            <button id="btnSubmit" className="btn" type="submit">Enviar</button>
        </form>
        </>
    )
}
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import spinnerImg from '../../assets/spin.svg';
import api from '../../services/api';
const Swal = require('sweetalert2');

export default function Login({ history }){

    const [emailLogin, setEmailLogin] = useState("");
    const [senhaLogin, setSenhaLogin] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function isLoged(){
            if(!sessionStorage.getItem('token')){
                return;
            } else{
                history.push('/tipoGarantia');
            }
        }
        isLoged();
    }, [history]);

    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    //Para desabilitar o botão assim que for feito a primeira requisição
    function disabledBtn() {
        var btn = document.getElementById("btnSubmit");
        btn.disabled = true; 
        btn.style.backgroundColor = '#F0E68C'
    };

    //Para habiltar o botão novamente caso caia no erro
    function abledBtn() {
        var btn = document.getElementById("btnSubmit");
        btn.disabled = false;
        btn.style.backgroundColor = '#ff9600';
        btn.innerText ='Emitir Certificado';
    };

    async function handleSubmit(event){
        event.preventDefault();

        disabledBtn();
        
        if(validateEmail(emailLogin) === false){
            Swal.fire({
                icon: 'error',
                title: 'Erro:',
                text: 'Por favor verifique se o Email contém @ e . e tente novamente!',
              });
              abledBtn();
        } else{

            setLoading(true);

            await api.post('/users/authenticate', {
                email: emailLogin,
                password: senhaLogin
            }).then(response =>{
                //Pegando Token de Sessão e salvando no sessionStorage(Salva o dado até ele sair da página)
                sessionStorage.setItem('token', response.data.token);

                //Salvando o user_id no LocalStorage
                localStorage.setItem('user_id', response.data.user.user_id);

                //Salvando o user_id no LocalStorage
                localStorage.setItem('user_cnpj', response.data.user.cnpj);

                setLoading(false);

                history.push('/tipoGarantia');
            }).catch(error =>{
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao realizar Login',
                    text: error.response.data,
                  });
                  setLoading(false);
                  abledBtn();
            });
        }
    }

    return (
        <>
        <form onSubmit={handleSubmit}>
            <label><strong>Email </strong></label>
            <input
                id="emailLogin"
                required
                maxLength="100"
                onChange={event => setEmailLogin(event.target.value)}
            />

            <label><strong>Senha </strong></label>
            <input
                type="password"
                id="senhaLogin"
                required
                maxLength="100"
                onChange={event => setSenhaLogin(event.target.value)}
            />

            {loading ? <img id="imgSpinner" name="imgSpinner" src={spinnerImg} alt="Loading"></img> : false}

            <button id="btnSubmit" className="btn" type="submit">Entrar</button>

            <Link to={{ pathname: "/forgot/password"}}> Esqueceu a Senha? Recupere Aqui </Link>

            <Link to={{ pathname: "/register"}}> Ainda não tem conta? Registre-se </Link>
        </form>
        </>
    )
}
import PasswordChecklist from "react-senha-checklist";
import React, { useState } from 'react';
import api from '../../services/api';
import spinnerImg from '../../assets/spin.svg';
import { useParams } from "react-router-dom";

const Swal = require('sweetalert2');

export default function Reset_password({ history }){

    const [newSenha, setNewSenha] = useState("");
    const [loading, setLoading] = useState(false);

    //Pegando da URL PARAMS o valor do token para alterar senha
    const { token } = useParams();

    //Função para validação de Senha
    function validateSenha(senha) {
        var re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        return re.test(senha);
    };

    //Para desabilitar o botão assim que for feito a primeira requisição
    function disabledBtn() {
        var btn = document.getElementById("btnSubmit");
        btn.disabled = true; 
        btn.style.backgroundColor = '#F0E68C'
    };

    //Para habiltar o botão novamente caso caia no erro
    function abledBtn() {
        var btn = document.getElementById("btnSubmit");
        btn.disabled = false;
        btn.style.backgroundColor = '#ff9600';
    };

    async function handleSubmit(event){
        event.preventDefault();

        disabledBtn();

        if (validateSenha(newSenha) === false){
            Swal.fire({
                icon: 'error',
                title: 'Erro:',
                text: 'Verifique se a senha contém os requisitos!',
              });
              abledBtn();
        }else{
            setLoading(true);
            await api.put('/reset/password/:token', {
                token: token,
                password: newSenha
            }).then(response =>{
                setLoading(false);
                Swal.fire({
                    icon: 'success',
                    title: 'Sucesso!',
                    text: 'Senha alterada com sucesso!',
                });

                localStorage.removeItem('emailLogin');

                history.push('/');
            }).catch(error =>{
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Erro na redefinição de senha',
                    text: error.response.data,
                  });
                  abledBtn();
            });
        }
    }

    return (
        <>
        <form onSubmit={handleSubmit}>

            <label><strong>Nova Senha </strong></label>
            <input
                type="password"
                id="newSenha"
                onChange={event => setNewSenha(event.target.value)}
            />

            <PasswordChecklist
				rules={["length","number"]}
				minLength={8}
				value={newSenha}
				onChange={(isValid) => {}}
			/>

            {loading ? <img id="imgSpinner" name="imgSpinner" src={spinnerImg} alt="Loading"></img> : false}

            <button id="btnSubmit" className="btn" type="submit">Atualizar</button>
        </form>
        </>
    )
}
import { cpf, cnpj } from 'cpf-cnpj-validator'; 
import PasswordChecklist from "react-senha-checklist";
import {mask, unMask} from 'remask';
import React, { useState } from 'react';
import api from '../../services/api';
import spinnerImg from '../../assets/spin.svg';

const Swal = require('sweetalert2');

export default function Register({ history }){

    const [emailLogin, setEmailLogin] = useState("");
    const [senhaLogin, setSenhaLogin] = useState("");
    const [cnpjLogin, setCnpjLogin] = useState("");
    const [loading, setLoading] = useState(false);

    //Máscara para o CNPJ / CPF
    const handleMaskCPFxCNPJ = (e) => {
        const originalValue = unMask(e.target.value);
        const maskedValue = mask(originalValue, ['999.999.999-99', '99.999.999/9999-99']);
        setCnpjLogin(maskedValue);
    };

    //Função para validação de E-mail
    function validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    //Função para validação de Senha
    function validateSenha(senha) {
        var re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
        return re.test(senha);
    };

    //Para desabilitar o botão assim que for feito a primeira requisição
    function disabledBtn() {
        var btn = document.getElementById("btnSubmit");
        btn.disabled = true; 
        btn.style.backgroundColor = '#F0E68C'
    };

    //Para habiltar o botão novamente caso caia no erro
    function abledBtn() {
        var btn = document.getElementById("btnSubmit");
        btn.disabled = false;
        btn.style.backgroundColor = '#ff9600';
        btn.innerText ='Cadastrar';
    };
    

    async function handleSubmit(event){
        event.preventDefault();

        disabledBtn();

        if(validateEmail(emailLogin) === false){
            Swal.fire({
                icon: 'error',
                title: 'Erro:',
                text: 'Por favor verifique se o Email contém @ e . e tente novamente!',
              });
              abledBtn();
        }
        else if(cnpj.isValid(unMask(cnpjLogin)) === false && cpf.isValid(unMask(cnpjLogin)) === false){
            Swal.fire({
                icon: 'error',
                title: 'Erro:',
                text: 'Por Favor, digite um CPF/CNPJ válido!',
              });
              abledBtn();
        }
        else if(validateSenha(senhaLogin) === false){
            Swal.fire({
                icon: 'error',
                title: 'Erro:',
                text: 'Verifique se a senha contém os requisitos!',
              });
              abledBtn();
        }
        else{
            setLoading(true)
            await api.post('/create/user', {
                email: emailLogin,
                password: senhaLogin,
                cnpj: unMask(cnpjLogin)
            }).then(response =>{
                Swal.fire({
                    icon: 'success',
                    title: 'Cadastro realizado com Sucesso!',
                    text: 'Por favor verifique seu e-mail para confirmar a conta!',
                });

                setLoading(false);
                history.push('/');
            }).catch(error =>{
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Erro ao realizar Cadastro',
                    text: error.response.data,
                  });
                  abledBtn();
            });
        }
    }

    return (
        <>
        <form onSubmit={handleSubmit}>
            <label><strong>Email </strong></label>
            <input
                id="emailLogin"
                required
                onChange={event => setEmailLogin(event.target.value)}
            />

            <label><strong>CNPJ / CPF </strong></label>
            <input
                id="cnpjLogin"
                required
                onChange={handleMaskCPFxCNPJ}
                value={cnpjLogin}
            />
            
            <label><strong>Senha </strong></label>
            <input
                type="password"
                id="senhaLogin"
                required
                onChange={event => setSenhaLogin(event.target.value)}
            />

            <PasswordChecklist
				rules={["length","number"]}
				minLength={8}
				value={senhaLogin}
				onChange={(isValid) => {}}
			/>

            {loading ? <img id="imgSpinner" name="imgSpinner" src={spinnerImg} alt="Loading"></img> : false}

            <button id="btnSubmit" className="btn" type="submit">Cadastrar</button>
        </form>
        </>
    )
}
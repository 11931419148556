import React, { useState } from 'react';
import api from '../../services/api';
import spinnerImg from '../../assets/spin.svg';
const jsdom = require('jsdom');
const Swal = require('sweetalert2');

export default function Nfe({ history }){
    const [chaveAcesso, setChaveAcesso] = useState('');
    const [loading, setLoading] = useState(false);

    
    function disabledBtn() {
        //Para desabilitar o botão assim que for feito a primeira requisição
        var btn = document.getElementById("btnSubmit");
        btn.disabled = true; 
        btn.style.backgroundColor = '#F0E68C'
    };

    function abledBtn() {
        //Para habiltar o botão novamente caso caia no erro
        var btn = document.getElementById("btnSubmit");
        btn.disabled = false;
        btn.style.backgroundColor = '#ff9600';
        btn.innerText ='Consultar e Cadastrar';
    };

    async function handleSubmit(event){
        event.preventDefault();

        //Para desabilitar o botão assim que for feito a primeira requisição
        disabledBtn();

        //Mascará para tirar os espaços e salvar no banco de dados
        var chaveAcessoSemEspaco = chaveAcesso.replace(/\s+/g, '');

        if(chaveAcessoSemEspaco.length < 44){
            //Biblioteca para alertas
            Swal.fire({
                icon: 'error',
                title: 'Erro:',
                text: 'Por favor digite uma Chave de Acesso Válida!',
              });
            //Caso caia neste erro, ele irá ativar o botão novamente!
            abledBtn();
        }else {

            setLoading(true);

            await api.get('/nfe/' + chaveAcessoSemEspaco + '/' + localStorage.getItem('user_id'), {
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('token')}`
                }
            }).then(response =>{
                const dom = new jsdom.JSDOM(response.data);
                const emitCNPJ = dom.window.document.querySelector("emit > CNPJ").textContent;

                if(response.status === 200 && emitCNPJ === "07263367000147"){

                    //Para salvar todas as informações necessárias no LocalStorage
                    async function salvarDados(){
                        localStorage.setItem('chaveAcesso', chaveAcessoSemEspaco);

                        const destNome = dom.window.document.querySelector("dest > xNome").textContent;
                        localStorage.setItem('destNome', destNome);

                        const destTelefone = dom.window.document.querySelector("dest > enderDest > fone").textContent;
                        localStorage.setItem('destTelefone', destTelefone);
                        
                        const destEmail = dom.window.document.querySelector("dest > email").textContent;
                        localStorage.setItem('destEmail', destEmail);

                        const destCidade = dom.window.document.querySelector("dest > enderDest > xMun").textContent;
                        localStorage.setItem('destCidade', destCidade);

                        const destEstado = dom.window.document.querySelector("dest > enderDest > UF").textContent;
                        localStorage.setItem('destEstado', destEstado);
                    }

                    const dest = dom.window.document.querySelector("dest");

                    //Caso o cliente seja CNPJ
                    if(dest.contains(dom.window.document.querySelector("dest > CNPJ"))){
                        const destCNPJCPF = dom.window.document.querySelector("dest > CNPJ").textContent;

                        //Comparando para ver se o CNPJ do usuário e o mesmo da Chave do CNPJ
                        if(localStorage.getItem('user_cnpj') === destCNPJCPF){
                            localStorage.setItem('destCNPJCPF', destCNPJCPF);
                            salvarDados();
                            setLoading(false);
                            history.push('/garantia');
                        }else{
                            setLoading(false);
                            Swal.fire({
                                icon: 'error',
                                title: 'Chave de Acesso inválida',
                                text: 'CNPJ da chave não é o mesmo do cadastro!',
                              });
                              abledBtn();
                        }
                    }
                    //Caso o cliente seja CPF
                    else if(dest.contains(dom.window.document.querySelector("dest > CPF"))){
                        const destCNPJCPF = dom.window.document.querySelector("dest > CPF").textContent;
                        //Comparando para ver se o CPF do usuário e o mesmo da Chave do CNPJ
                        if(localStorage.getItem('user_cnpj') === destCNPJCPF){
                            localStorage.setItem('destCNPJCPF', destCNPJCPF);
                            salvarDados();
                            setLoading(false);
                            history.push('/garantia');
                        }else{
                            setLoading(false);
                            Swal.fire({
                                icon: 'error',
                                title: 'Chave de Acesso inválida',
                                text: 'CPF da chave não é o mesmo do cadastro!',
                              });
                              abledBtn();
                        }
                    }
                }else{
                    setLoading(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Chave de Acesso inválida',
                        text: 'por favor verifique e tente novamente.',
                      });
                      abledBtn();
                }
            }).catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: 'error',
                    title: 'Chave de Acesso inválida',
                    text: error.response.data,
                  });
                  abledBtn();
            });;          
        }
    }

    return (
        <>
        <p><strong>Digite a Chave de Acesso da sua Nota Fiscal: </strong></p>
        <form onSubmit={handleSubmit}>
            <input
                id="chaveAcesso"
                type="number"
                placeholder="Chave de Acesso (Sem espaços)"
                value={chaveAcesso}
                required
                maxLength="44"
                onChange={event => setChaveAcesso(event.target.value)}
            />

            {loading ? <img id="imgSpinner" name="imgSpinner" src={spinnerImg} alt="Loading"></img> : false}

        <button id="btnSubmit" className="btn" type="submit">Consultar e Cadastrar</button>
        </form>
        </>
    )
}
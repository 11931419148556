import React from 'react';
import { Switch, Route, Redirect, HashRouter } from "react-router-dom";
import { isAuthenticated } from './auth';

import Garantia from './pages/Garantia';
import Nfe from './pages/Nfe';
import TipoGarantia from './pages/TipoGarantia';
import Login from './pages/Login';
import Register from './pages/Register';
import Forgot_password from './pages/Forgot_password';
import Reset_password from './pages/Reset_password';
import Status_account from './pages/Status_account';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props =>(
        isAuthenticated() ? (
            <Component {...props} />
        ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
    )} />
)

export default function Routes() {
    return(
        <HashRouter>
            <Switch>
                <Route path="/" exact component={Login}/>
                <Route path="/forgot/password" component={Forgot_password}/>
                <Route path="/reset/password/:token" component={Reset_password}/>
                <Route path="/register" component={Register}/>
                <Route path="/update/account/status/:token" component={Status_account}/>
                <PrivateRoute path="/tipoGarantia" component={TipoGarantia}/>
                <PrivateRoute path="/consultaNfe" component={Nfe}/>
                <PrivateRoute path="/garantia" component={Garantia}/>
            </Switch>
        </HashRouter>
    );
}